import React from 'react';

import GoogleAuthorize from '@/components/commons/icons/GoogleAuthorize';
import GoogleMinimalist from '@/components/commons/icons/GoogleMinimalist';

import GoogleCtaSelector from './GoogleCtaSelector';

function SignInWithGoogle({
    className, signup = false, authorize = false, silent = false,
}) {
    // achieve the square, no-text button for `silent` mode.
    const actionName = signup ? 'up' : 'in';

    return (
        <div className={className}>
            {
                silent ? (<GoogleMinimalist />) : (
                    <div>
                        {(authorize && (
                            <div>
                                <span>Grant Permission</span>
                                <br />
                                <br />
                                <GoogleAuthorize />
                            </div>

                        )) || (
                            <GoogleCtaSelector type={actionName} />
                        )}
                    </div>
                )
            }
        </div>
    );
}

export default SignInWithGoogle;
