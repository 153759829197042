import React from 'react';

import GoogleSignIn from '@/components/commons/icons/GoogleSignIn';
import GoogleSignUp from '@/components/commons/icons/GoogleSignUp';

interface Props {
    readonly type?: string;
}

export default function GoogleCtaSelector({ type = 'in' }: Props) {
    return (
        type === 'in' ? (
            <GoogleSignIn />
        ) : (
            <GoogleSignUp />
        )
    );
}
